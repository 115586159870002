<template>
    <div class='recharge_filter_m p-r'>
      <van-dropdown-menu :close-on-click-outside="false">
        <van-dropdown-item
          :title="dateTime ? dateTime : $sys('mall.dateRange')" ref="itemDate"
          @open="handleOpen"
        >

        </van-dropdown-item>
      </van-dropdown-menu>

      <p class="tips p-a">*{{ $sys('warn.recordTips') }}</p>

      <van-calendar
        v-model='visible'
        :min-date='dateRange.min'
        :max-date='dateRange.max'
        :default-date='dataVal'
        type='range'
        @confirm='handleDateSelect'
        @close='handleClose'
      />
    </div>
  </template>

  <script>
  import { mapState } from 'vuex'
  import { DropdownMenu, DropdownItem, Calendar } from 'vant'

  export default {
    created() {
      this.dateVal = []
    },
    components: {
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Calendar.name]: Calendar
    },
    data() {
      return {
        visible: false
      }
    },
    computed: {
      ...mapState('order', ['orderSearch', 'dateRange']),
      dataVal() {
        return [this.orderSearch.startTime, this.orderSearch.endTime]
      },
      dateTime() {
        return this.orderSearch.startTime.toLocaleDateString() + ' - ' + this.orderSearch.endTime.toLocaleDateString()
      }
    },
    methods: {
      handleOpen() {
        this.visible = true
        this.$emit('onBegin')
      },
      handleDateSelect(val) {
        val && this.$emit('onSearch', val)
        this.handleClose()
      },
      handleClose() {
        this.visible = false
        this.$refs.itemDate.toggle(false)
      },
      handleConfirm() {
        this.dateVal && this.$emit('onSearch', this.dateVal)
      }
    }
  }
  </script>

  <style lang='scss' scoped>
  .recharge_filter_m {
    background: #fff;
    -webkit-box-shadow: -2px 11px 8px -4px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: -2px 11px 8px -4px rgba(0, 0, 0, 0.11);
    box-shadow: -2px 11px 8px -4px rgba(0, 0, 0, 0.11);

    .tips {
        width: 340px;
        height: 46px;
        color: #5d5d5d;
        right: 40px;
        top: 15px;
        font-size: 20px;
        line-height: 26px;
    }

    .van-dropdown-menu {
      width: 50%;
    }
  }
  </style>
<template>
    <div class="record_list">
        <Title :name="$sys('account.rechargeRecord')" :style="isMobile ? {marginLeft: '20px'} : { marginBottom: '20px' }"></Title>

        <TableFilter />
        
        <TableList />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Title from '../Title'
import TableFilter from './TableFilter'
import TableList from './TableList'


export default {
    components: {
        Title,
        TableFilter,
        TableList
    },
    computed: {
        ...mapGetters(['isMobile'])
    }
}
</script>

<style lang="scss" scoped>
.record_list {
    background-color: #fff;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "record_list" },
    [
      _c("Title", {
        style: _vm.isMobile ? { marginLeft: "20px" } : { marginBottom: "20px" },
        attrs: { name: _vm.$sys("account.rechargeRecord") },
      }),
      _c("TableFilter"),
      _c("TableList"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <component 
        :is="isMobile ? 'ListM' : 'ListPc'" 
        @onPage="handlePage"
        @onCancel="cancelOrder"
    ></component>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import ListPc from './Pc'
import ListM from './M'

export default {
    components: {
        ListPc,
        ListM
    },
    computed: {
        ...mapState('order', ['orderPage']),
        ...mapState('user', ['userInfo']),
        ...mapGetters(['isMobile'])
    },
    created() {
        this.getOrderList()
    },
    methods: {
        ...mapMutations('order', ['SET_ORDER_PAGE']),
        ...mapActions('order', ['getOrderList', 'cancelOrder']),
        handlePage({ current }) {
            this.SET_ORDER_PAGE({
                ...this.orderPage,
                current
            })
            this.getOrderList()
        }
    },
    watch: {
        userInfo() {
            this.handlePage({ current: 1 })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
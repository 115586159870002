<template>
    <div class="page_record">

        <Banner></Banner>

        <div class="page_content">
            <div class="record_wrap">
                <RoleInfo />

                <RecordList />
            </div>
        </div>
    </div>
    
</template>

<script>
import Banner from "@/components/Banner"
import RoleInfo from './components/RoleInfo'
import RecordList from './components/RecordList'

export default {
    name: 'StepPay',
    components: {
        RoleInfo,
        RecordList,
        Banner
    }
}
</script>

<style lang="scss" scoped>
.page_record {
    .page_content {
        background-color: #fff;
        border-radius: 15px;
        margin-top: 10px;
    }
}
</style>
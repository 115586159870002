<template>
    <div>
        <el-table v-loading="loading" :data="orderList" style="width: 100%" class="table_recharge_list" :row-class-name="tableRowClassName"
            header-row-class-name="recharge_list_header">
            <el-table-column prop="orderCode" :label="$sys('mall.orderNumber')" :align="'center'" :min-width='120'>
            </el-table-column>
            <el-table-column prop="createTime" :label="$sys('mall.date')" width="180" :align="'center'">
            </el-table-column>
            <el-table-column prop="commodityName" :label="$sys('mall.productName')" :align="'center'">
            </el-table-column>
            <el-table-column prop="orderPrizeShow" :label="$sys('mall.orderAmount')" :align="'center'">
            </el-table-column>
            <el-table-column :label="$sys('mall.orderStatus')" :align="'center'">
                <template slot-scope="scope">
                    <span class="status" :class="{
                        'red': scope.row.orderStatus == 0 || scope.row.orderStatus == 11,
                        'green': scope.row.orderStatus == 1 || scope.row.orderStatus == 2,
                        'gray': scope.row.orderStatus == 3
                        }">
                        {{ scope.row.status ? $sys(`mall.status.${scope.row.status}`) : '' }}
                    </span>
                    <a href="javascript:;" v-if="scope.row.canCancel" class="blue btn_cancel" @click="$emit('onCancel', scope.row.orderCode)">
                        {{ $sys('mall.cancelOrder') }}
                    </a>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            class="pagination_recharge_list"
            background 
            layout="prev, pager, next" 
            :current-page="orderPage.current"
            :page-size="orderPage.size"
            :total="orderPage.total" 
            @current-change="current => $emit('onPage', {current})">
        </el-pagination>
    </div>

</template>

<script>
import { mapState } from 'vuex'
import { Table, TableColumn, Pagination } from 'element-ui'

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Pagination.name]: Pagination
    },
    computed: {
        ...mapState('order', ['orderList', 'orderPage', 'loading'])
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
        }
    }
}
</script>

<style lang="scss" scoped>
.btn_cancel {
  display: block;
}
.status{
  display: block;
  white-space: nowrap;
}
</style>
<template>
    <component
      :is="isMobile ? 'FilterM' : 'FilterPc'"
      :default-date='defaultDate'
      @onBegin='handleBegin'
      @onSearch='handleSearch'
    ></component>
  </template>
  
  <script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import FilterPc from './Pc'
  import FilterM from './M'
  import { logChangeDate, logSelectDate } from '@/utils/log'
  
  export default {
    components: {
      FilterPc,
      FilterM
    },
    computed: {
      ...mapGetters(['isMobile']),
      ...mapState('order', ['orderSearch', 'orderPage']),
      defaultDate(){
        return [this.orderSearch.startTime, this.orderSearch.endTime]
      }
    },
    methods: {
      ...mapMutations('order', ['SET_ORDER_SEARCH', 'SET_ORDER_PAGE']),
      ...mapActions('order', ['getOrderList']),
      handleBegin() {
        logChangeDate()
      },
      handleSearch(arr) {
        this.SET_ORDER_SEARCH({
          startTime: arr[0],
          endTime: arr[1]
        })
        this.SET_ORDER_PAGE({
          ...this.orderPage,
          current: 1
        })
        this.getOrderList()
  
        logSelectDate()
      }
    }
  }
  </script>
  
  <style lang='scss' scoped>
  
  </style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-list",
    {
      staticClass: "recharge_list",
      attrs: {
        finished: _vm.finished,
        "loading-text": _vm.$sys("warn.loading"),
        "finished-text": _vm.$sys("warn.noMore"),
        offset: "20",
        "immediate-check": false,
        "error-text": "",
      },
      on: { load: _vm.onLoad },
      model: {
        value: _vm.isLoading,
        callback: function ($$v) {
          _vm.isLoading = $$v
        },
        expression: "isLoading",
      },
    },
    _vm._l(_vm.tableList, function (item) {
      return _c("div", { key: item.orderNumber }, [
        _c("div", { staticClass: "item" }, [
          _c("p", { staticClass: "date" }, [
            _vm._v(" " + _vm._s(_vm.$sys("mall.date")) + "："),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(item.createTime)),
            ]),
          ]),
          _c("p", { staticClass: "name" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$sys("mall.productName")) +
                "：" +
                _vm._s(item.commodityName) +
                " "
            ),
          ]),
          _c("p", { staticClass: "orderNumber" }, [
            _vm._v(" " + _vm._s(_vm.$sys("mall.orderNumber")) + "："),
            _c("span", { staticClass: "number" }, [
              _vm._v(_vm._s(item.orderCode)),
            ]),
          ]),
          _c("p", { staticClass: "amount" }, [
            _vm._v(" " + _vm._s(item.orderPrizeShow) + " "),
          ]),
          _c("div", { staticClass: "status" }, [
            _c(
              "p",
              {
                class: {
                  fail: item.orderStatus == 0 || item.orderStatus == 11,
                  ok: item.orderStatus == 1 || item.orderStatus == 2,
                  invalid: item.orderStatus == 3,
                },
              },
              [
                _vm._v(
                  _vm._s(
                    item.status ? _vm.$sys(`mall.status.${item.status}`) : ""
                  )
                ),
              ]
            ),
          ]),
          _c("p", { staticClass: "opt-panel" }, [
            item.canCancel
              ? _c(
                  "a",
                  {
                    staticClass: "cancel-order cancel",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("onCancel", item.orderCode)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$sys("mall.cancelOrder")) + " ")]
                )
              : _vm._e(),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
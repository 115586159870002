var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.isMobile ? "FilterM" : "FilterPc", {
    tag: "component",
    attrs: { "default-date": _vm.defaultDate },
    on: { onBegin: _vm.handleBegin, onSearch: _vm.handleSearch },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recharge_filter_m p-r" },
    [
      _c(
        "van-dropdown-menu",
        { attrs: { "close-on-click-outside": false } },
        [
          _c("van-dropdown-item", {
            ref: "itemDate",
            attrs: {
              title: _vm.dateTime ? _vm.dateTime : _vm.$sys("mall.dateRange"),
            },
            on: { open: _vm.handleOpen },
          }),
        ],
        1
      ),
      _c("p", { staticClass: "tips p-a" }, [
        _vm._v("*" + _vm._s(_vm.$sys("warn.recordTips"))),
      ]),
      _c("van-calendar", {
        attrs: {
          "min-date": _vm.dateRange.min,
          "max-date": _vm.dateRange.max,
          "default-date": _vm.dataVal,
          type: "range",
        },
        on: { confirm: _vm.handleDateSelect, close: _vm.handleClose },
        model: {
          value: _vm.visible,
          callback: function ($$v) {
            _vm.visible = $$v
          },
          expression: "visible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page_record" },
    [
      _c("Banner"),
      _c("div", { staticClass: "page_content" }, [
        _c(
          "div",
          { staticClass: "record_wrap" },
          [_c("RoleInfo"), _c("RecordList")],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.isMobile ? "ListM" : "ListPc", {
    tag: "component",
    on: { onPage: _vm.handlePage, onCancel: _vm.cancelOrder },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
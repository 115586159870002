var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table_filter flex-start" }, [
    _c(
      "div",
      [
        _c("span", { staticClass: "demonstration" }, [
          _vm._v(_vm._s(_vm.$sys("mall.dateRange"))),
        ]),
        _c("el-date-picker", {
          attrs: {
            clearable: false,
            "picker-options": {
              disabledDate(date) {
                return date > _vm.pcDateRange.max || date < _vm.pcDateRange.min
              },
            },
            type: "daterange",
            "start-placeholder": _vm.$sys("mall.dateBegin"),
            "end-placeholder": _vm.$sys("mall.dateEnd"),
          },
          on: {
            focus: _vm.handleFocus,
            blur: _vm.handleBlur,
            change: _vm.handleChange,
          },
          model: {
            value: _vm.dateVal,
            callback: function ($$v) {
              _vm.dateVal = $$v
            },
            expression: "dateVal",
          },
        }),
        _c(
          "el-button",
          {
            staticStyle: { "margin-left": "10px" },
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.handleConfirm()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$sys("btn.confirm")))]
        ),
        _c("div", { staticClass: "tips" }, [
          _vm._v("*" + _vm._s(_vm.$sys("warn.recordTips"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }